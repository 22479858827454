<template>
  <div class="small-footer">
    <template v-if="path === '/home'">
      <div class="info">
        <!-- <div class="label">关于我们</div> -->
        <div class="width">
          <div class="item" v-for="item in routerList" :key="item.path" @click="goJump(item)">{{ item.meta.title }}</div>
        </div>
      </div>
      <div class="info">
        <div class="label">友情链接</div>
        <div class="height" v-for="item in otherList" :key="item.name" @click="jump(item)">{{ item.name }}</div>
      </div>
      <div class="info">
        <div class="label">咨询热线</div>
        <div class="other">{{ phoneNumber }}</div>
      </div>
      <div class="info">
        <div class="label">邮箱</div>
        <div class="other">{{ mailbox }}</div>
      </div>
      <div class="line" />
    </template>
    <div class="last">
      <span>威尔尼斯康养科技(深圳)有限公司</span>
      <span @click="goBeian">粤ICP备2021101502号</span>
    </div>
  </div>
</template>

<script>
import { phoneNumber, mailbox, beiAn } from '@/constant'
export default {
  name: 'SmallFooter',
  data() {
    return {
      phoneNumber,
      mailbox,
      otherList: [
        { name: '天津英讯通科技股份有限公司', path: 'https://www.yingxuntong.com/' },
        { name: '奥登特康养管理（深圳）有限公司', path: 'http://www.ardentlivinggroup.com/' }
      ]
    }
  },
  computed: {
    path() {
      return this.$route.path
    },
    routerList() {
      return this.$router.options.routes[1].children
    }
  },
  methods: {
    goJump(item) {
      if (item.path === this.$route.path) return
      this.$router.push({ path: item.path })
    },
    jump(item) {
      window.open(item.path)
    },
    goBeian() {
      window.open(beiAn)
    }
  }
}
</script>

<style lang="scss" scoped>
.small-footer {
  padding: 24px 16px;
  background: #4e5969;
  font-size: 14px;
  color: rgba(255, 255, 255, .8);
  .info {
    margin-bottom: 24px;
    .label {
      margin-bottom: 16px;
    }
    .width {
      display: flex;
      align-items: center;
      .item {
        margin-right: 32px;
      }
    }
    .height {
      margin-top: 16px;
      &:first-child {
        color: #A3ABB5;
      }
    }
    .other {
      font-size: 20px;
      margin-top: 8px;
    }
  }
  .line {
    height: 1px;
    background: rgba(255, 255, 255, .5);
    margin-bottom: 16px;
  }
  .last {
    display: flex;
    font-size: 13px;
    justify-content: space-between;
  }
}
</style>