<template>
  <div class="footer">
    <div class="content-main">
      <div class="footer-top">
        <div class="left">
          <div class="link w1">
            <div class="link-item" v-for="item in routerList" :key="item.path" @click="goJump(item)">{{ item.meta.title }}</div>
          </div>
          <div class="link">
            <div class="link-item color" v-for="item in otherList" :key="item.name" @click="jump(item)">{{ item.name }}</div>
          </div>
        </div>
        <div class="right">
          <div>
            <div class="label">咨询热线</div>
            <div class="value">{{ phoneNumber }}</div>
          </div>
          <div>
            <div class="label">邮箱</div>
            <div class="value">{{ mailbox }}</div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <span class="text">威尔尼斯康养科技(深圳)有限公司</span>
        <span class="text" @click="goBeian">粤ICP备2021101502号</span>
      </div>
    </div>
  </div>
</template>

<script>
import { phoneNumber, mailbox, beiAn } from '@/constant'
export default {
  name: 'FooterIndex',
  computed: {
    routerList() {
      return this.$router.options.routes[1].children
    }
  },
  data() {
    return {
      phoneNumber,
      mailbox,
      otherList: [
        { name: '友情链接' },
        { name: '天津英讯通科技股份有限公司', path: 'https://www.yingxuntong.com/' },
        { name: '奥登特康养管理（深圳）有限公司', path: 'http://www.ardentlivinggroup.com/' }
      ]
    }
  },
  methods: {
    goJump(item) {
      if (item.path === this.$route.path) return
      this.$router.push({ path: item.path })
    },
    goBeian() {
      window.open(beiAn)
    },
    jump(item) {
      if (!item.path) return
      window.open(item.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 389px;
  background: #4E5969;
  color: rgba(255, 255, 255, .8);
  .content-main {
    padding: 64px 0;
    .footer-top {
      display: flex;
      padding-bottom: 63px;
      border-bottom: 1px solid #99a0a9;
      .left {
        display: flex;
        font-size: 14px;
        .link {
          margin-right: 232px;
          .link-item {
            margin-bottom: 16px;
            cursor: pointer;
            &:hover {
              color: #fff;
              text-decoration: underline;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          .color {
            &:first-child {
              text-decoration: none;
              color: #A3ABB5;
            }
          }
        }
        .w1 {
          width: 85px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 165px;
        border-left: 1px solid #9da4ad;
        padding-left: 80px;
        .label {
          font-size: 14px;
        }
        .value {
          font-size: 20px;
        }
      }
    }
    .footer-bottom {
      padding-top: 16px;
      .text {
        margin-right: 112px;
        font-size: 14px;
        &:hover:last-child {
          cursor: pointer;
          text-decoration: underline;
          color: #fff;
        }
      }
    }
  }
}
</style>
