<template>
  <div class="small-navbar">
    <div class="navbar-title">{{ title }}</div>
    <div class="icon" @click="drawer = true"><img src="@/assets/home/menu.png" alt=""></div>
    <el-drawer
      title=""
      width="80%"
      :visible.sync="drawer"
    >
      <el-menu
        background-color="#ffffff"
        text-color="#000000"
        active-text-color="#000000"
        router
        class="el-menu-vertical-demo"
      >
        <template v-for="item in menuList">
          <template v-if="item.children && item.children.length">
            <el-submenu :key="item.path" :index="item.path">
              <template slot="title">
                <span>{{ item.meta.title }}</span>
              </template>
              <el-menu-item-group v-for="child in item.children" :key="child.path">
                <el-menu-item v-if="!child.meta.hidden" :index="child.path">{{ child.meta.title }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :key="item.path" :index="item.path">
              <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'SmallNavbar',
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    title() {
      return this.$route.meta.title
    },
    menuList() {
      return this.$router.options.routes[1].children
    }
  },
  watch: {
    title() {
      this.drawer = false
    }
  }
}
</script>

<style lang="scss" scoped>
.small-navbar {
  width: 100%;
  height: 44px;
  text-align: center;
  line-height: 44px;
  font-size: 17px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9999;
  border-bottom: 1px solid #f2f3f5;
  .navbar-title {
    font-weight: 600;
  }
  .icon {
    position: absolute;
    right: 18px;
    top: 6px;
    width: 24px;
    height: 24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  ::v-deep(.el-drawer) {
    width: 80% !important;
    .el-drawer__body {
      padding-left: 32px;
      .el-submenu, .el-menu-item {
        text-align: left;
        border-bottom: 1px solid #F2F3F5;
      }
      .el-submenu.is-opened {
        border-bottom: none;
      }
    }
  }
}
</style>