import Mock from "mockjs";
import { getQuery } from "./utils";

const data1 = [
  {
    id: 1,
    label: "康养服务的智能化、高效化和个性化",
    content:
      "在康养领域中，通过运用先进的信息技术、智能设备等手段，实现康养服务的智能化、高效化和个性化。该方案旨在提高康养服务的质量和效率，提升老年人的生活质量和幸福感。",
  },
  {
    id: 2,
    label: "健康实时监测、分析和管理",
    content:
      "包括智能化的健康监测、照护服务、康复辅助、居住环境等。通过运用物联网、大数据、人工智能等技术，实现对老年人健康状况的实时监测、分析和管理，为老年人提供更加精准、个性化的服务。",
  },
  {
    id: 3,
    label: "智能化优势",
    content:
      "在于提高服务效率、降低成本、提升老年人生活质量等方面。通过智能化手段，可以实现对老年人健康状况的实时监测和管理，及时发现和处理问题，减少医疗费用和照护成本。同时，智能化服务可以为老年人提供更加便捷、高效的服务，提高老年人的生活质量和幸福感。",
  },
  {
    id: 4,
    label: "未来前景",
    content:
      "随着技术的不断进步和应用领域的拓展，康养智能化解决方案将得到更加广泛的应用和推广。相信在不久的将来，康养智能化服务将成为一种普及化的生活方式和服务模式，为更多的人带来便利和幸福。",
  },
];

const data2 = [
  {
    id: 1,
    label: "产学研联动",
    content:
      "康养运营管理系统与与交通大学科技部和康养类专业院校等各大院校的产学研成果深度结合，产业、院校、科研机构相互配合，发挥各自优势，形成了研究、开发、运营一体化的先进的系统，并纳入国家标准，在产学研的深度结合下，系统做到了专业与运营结合，实现了精准化评估和个性化服务。",
  },
  {
    id: 2,
    label: "全球经验，本土服务",
    content:
      "以国内外实体项目运营实践经验为基础，结合德国、美国和日本的专业康复护理教程，为机构/居家康养提供全方位、高标准的服务。通过深入了解和学习不同国家的康复护理理念和技术，能够为用户提供更加科学、专业的照护方案，满足不同用户的需求。这种国际化的合作与交流，使得此系统在康复护理领域具有更强的竞争力，为机构/居家康养的发展注入新的活力。",
  },
  {
    id: 3,
    label: "全面覆盖机构和居家场景",
    content:
      "通过与产学研的深度结合，以及三十余年国际化、本土化实践经验，使康养数据运营体系实现了从机构、社区到居家的一体化服务闭环。",
  },
];

// 详情页面的文字信息
const groupData = {
  detail1: {
    title: "两斗床头柜",
    content:
      "这款抽屉柜外观简洁百搭，无论放在卧室还是任何地方都很合适。可作为额外的储物方案，用来收纳各式小物件，也可作为床头桌使用。底部带有脚轮，便于轻松移动。",
    size: ["宽度:54 厘米 X 深度:45 厘米 X 高度:70 厘米"],
    place: "客厅, 卧室",
  },
  detail2: {
    title: "单人沙发",
    content:
      "采用柔软的布料和填充物，坐感和靠背都非常舒适，适合长时间坐着休息或阅读。",
    size: ["宽度:80 厘米 X 深度:86 厘米 X 高度:79 厘米"],
    place: "客厅, 卧室",
  },
  detail3: {
    title: "棋桌",
    content:
      "不仅是下棋的用具，还可以用于放置物品、阅读、写作或作为餐桌使用，具有多种用途。",
    size: ["宽度:100 厘米 X 长度:100 厘米 X 高度:77.5 厘米"],
    place: "客厅, 卧室",
  },
  detail4: {
    title: "升降桌",
    content:
      "升降自由，可站可坐交替办公，操作简便，只需按动把手就能轻松调节到指定高度。",
    size: ["宽度:41 厘米 X 长度:86 厘米 X 高度:72-102 厘米"],
    place: "客厅, 卧室",
  },
  detail5: {
    title: "六斗抽屉柜",
    content:
      "木质柜子具有自然纹理和温馨感，造型简单，无论放在卧室，或是其他地方都能和谐搭配。",
    size: ["宽度:42 厘米 X 长度:80 厘米 X 高度:68.5 厘米"],
    place: "客厅, 卧室",
  },
  detail6: {
    title: "躺椅",
    content: "电动躺椅，电竞追剧，办公午休，开启多元躺平生活。",
    size: ["宽度:73 厘米 X 深度:83 厘米 X 高度:61-78 厘米"],
    place: "客厅, 卧室",
  },
  detail7: {
    title: "换鞋凳",
    content: "高低凳一体设计，低凳可旋转至高凳下方，减少占用空间，方便收纳。",
    size: [
      "高凳 宽度:35 厘米 X 长度:35 厘米 X 高度:43 厘米",
      "低凳 宽度:27 厘米 X 长度:27 厘米 X 高度:22 厘米",
    ],
    place: "客厅, 卧室",
  },
  detail8: {
    title: "雪茄椅",
    content:
      "注重人体工程学，能够提供良好的坐姿和舒适度，可以缓解长时间坐着造成的疲劳感。",
    size: [
      "扶手椅 宽度:54 厘米 X 深度:61 厘米 X 高度:103 厘米",
      "脚凳 宽度:38 厘米 X 深度:46 厘米 X 高度:40 厘米",
    ],
    place: "客厅, 卧室",
  },
  detail9: {
    title: "椅子",
    content:
      "表面质地细腻，触感舒适，给人以温暖、自然的感觉，耐用的皮质垫套可以塔配各种室内风格。",
    size: ["宽度:60 厘米 X 深度:50 厘米 X 高度:87 厘米"],
    place: "客厅, 卧室",
  },
  detail10: {
    title: "椅子",
    content: "采用柔软的皮革和经过精心设计的靠背和座垫，提供舒适的坐感和支撑。",
    size: ["宽度:52 厘米 X 深度:55 厘米 X 高度:82 厘米"],
    place: "客厅, 卧室",
  },
  detail11: {
    title: "三人沙发",
    content:
      "采用宽大的座垫和靠背设计，可以提供舒适的坐姿和靠背支撑，有效缓解长时间坐着造成的疲劳感。",
    size: ["宽度:206 厘米 X 深度:80 厘米 X 高度:77 厘米"],
    place: "客厅, 卧室",
  },
  detail12: {
    title: "二人沙发",
    content:
      "采用宽大的座垫和靠背设计，可以提供舒适的坐姿和靠背支撑，有效缓解长时间坐着造成的疲劳感。",
    size: ["宽度:147 厘米 X 深度:80 厘米 X 高度:77 厘米"],
    place: "客厅, 卧室",
  },
  detail13: {
    title: "椅子",
    content:
      "这款坚固的椅子由实木制成。略带弧度的靠背，且椅子的空间较大，你可以轻松找到自己最舒适的姿势。",
    size: ["宽度:46 厘米 X 深度:61.3 厘米 X 高度:71 厘米"],
    place: "客厅, 卧室",
  },
};

// 列表图片
const groupArr = [];

// 详情页图片长图
const groupDetailArr = [];

// 所有详情页面的小图
const groupSmallObj = {};

// 获取图片列表数据
for (let i = 0; i < 13; i++) {
  groupArr.push({
    id: i + 1,
    url: require(`@/assets/product/group/group${i + 1}.png`),
    type:
      i % 7 === 0
        ? 7
        : i % 6 === 0
        ? 6
        : i % 5 === 0
        ? 5
        : i % 4 === 0
        ? 4
        : i % 3 === 0
        ? 3
        : 2,
  });
}

// 获取详情页面长图
for (let i = 0; i < 13; i++) {
  groupDetailArr.push(
    require(`@/assets/product/groupDetail/group${i + 1}.png`)
  );
}

// 获取详情页面所有小图
for (let i = 0; i < 38; i++) {
  groupSmallObj[`url${i + 1}`] = require(`@/assets/product/groupSmall/group${
    i + 1
  }.png`);
}

const smallImgArr = [
  [groupSmallObj.url1, groupSmallObj.url2, groupSmallObj.url3],
  [groupSmallObj.url4, groupSmallObj.url5, groupSmallObj.url6],
  [groupSmallObj.url7, groupSmallObj.url8, groupSmallObj.url9],
  [groupSmallObj.url10, groupSmallObj.url11, groupSmallObj.url12],
  [groupSmallObj.url13, groupSmallObj.url14, groupSmallObj.url15],
  [groupSmallObj.url16, groupSmallObj.url17, groupSmallObj.url18],
  [groupSmallObj.url19, groupSmallObj.url20, groupSmallObj.url21],
  [groupSmallObj.url22, groupSmallObj.url23],
  [groupSmallObj.url24, groupSmallObj.url25, groupSmallObj.url26],
  [groupSmallObj.url27, groupSmallObj.url28, groupSmallObj.url29],
  [groupSmallObj.url30, groupSmallObj.url31, groupSmallObj.url32],
  [groupSmallObj.url33, groupSmallObj.url34, groupSmallObj.url35],
  [groupSmallObj.url36, groupSmallObj.url37, groupSmallObj.url38],
];

// 获取解决方案文字
Mock.mock("/api/product/solution/getText", "get", () => {
  return {
    code: 200,
    msg: "操作成功",
    data: data1,
  };
});

// 获取运营文字
Mock.mock("/api/product/operation/getText", "get", () => {
  return {
    code: 200,
    msg: "操作成功",
    data: data2,
  };
});

// 查询产品列表
Mock.mock(/api\/product\/furniture\/getGroupList/, "get", (options) => {
  const ids = JSON.parse(getQuery(options.url, "ids"));
  const pageSize = getQuery(options.url, "pageSize");
  let data = [];
  if (ids.includes(1)) {
    data = groupArr.slice(0, pageSize);
  } else {
    groupArr.forEach((item) => {
      if (ids.includes(item.type)) {
        data.push(item);
      }
    });
  }
  return {
    code: 200,
    msg: "操作成功",
    data: data,
  };
});

// 获取产品详情
Mock.mock(/api\/product\/furniture\/getGroupDetail/, "get", (options) => {
  const id = JSON.parse(getQuery(options.url, "id"));
  const data = {
    ...groupData[`detail${id}`],
    bigImgUrl: groupDetailArr[id - 1],
    smallList: smallImgArr[id - 1],
  };
  return {
    code: 200,
    msg: "操作成功",
    data: data,
  };
});
