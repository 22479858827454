import Vue from "vue";
import Router from "vue-router";
import Layout from "../layout";

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        meta: { title: "首页" },
        component: () => import("@/views/home/index"),
      },
      {
        path: "/about",
        meta: { title: "关于我们", breadcrumb: true },
        component: () => import("@/views/about/index"),
      },
      {
        path: "/product",
        redirect: "/product/solution",
        meta: { title: "产品中心", breadcrumb: true },
        component: () => import("@/views/product/index"),
        children: [
          {
            path: "/product/solution",
            meta: { title: "康养智能化解决方案", breadcrumb: true },
            component: () => import("@/views/product/solution/index"),
          },
          {
            path: "/product/operation",
            meta: { title: "康养运营管理系统", breadcrumb: true },
            component: () => import("@/views/product/operation/index"),
          },
          {
            path: "/product/furniture",
            meta: { title: "康养功能性家具", breadcrumb: true },
            component: () => import("@/views/product/furniture/index"),
          },
          {
            path: "/product/furnitureDetails",
            meta: {
              title: "产品",
              breadcrumb: true,
              hidden: true,
              noLayout: true,
            },
            component: () => import("@/views/product/furnitureDetails/index"),
          },
        ],
      },
      {
        path: "/contact",
        meta: { title: "联系我们", breadcrumb: true },
        component: () => import("@/views/contact/index"),
      },
    ],
  },
];

const createRouter = () =>
  new Router({
    mode: "hash", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
