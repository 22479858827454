import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "./utils/rem";
import "./styles/index.scss";
import "./mock/index";

import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  ak: "pHNeTuQUwX76LXwKpoDoHRvClxdgULFV",
});

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
});
