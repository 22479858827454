<template>
  <div class="navbar">
    <div class="content-main header">
      <div class="title">{{ title }}</div>
      <div class="sidebar">
        <div
          :class="$route.path.indexOf(item.path) !== -1 ? 'sidebar-item is-active' : 'sidebar-item'"
          v-for="item in routerList"
          :key="item.path"
          @click="goRouter(item)"
        >{{ item.meta.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      title: 'WELLNESS',
      activeIndex: '0'
    }
  },
  computed: {
    routerList() {
      return this.$router.options.routes[1].children
    }
  },
  methods: {
    goRouter(item) {
      if (this.$route.path.indexOf(item.path) !== -1) return
      this.$router.push(item.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 104px;
  width: 100%;
  border-bottom: 1px solid #C9CDD4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 9999;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 40px;
      color: #1D2129;
    }
    .sidebar {
      display: flex;
      align-items: center;
      height: 100%;
      .sidebar-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 18px;
        margin-left: 72px;
        min-width: 72px;
        color: #4E5969;
        box-sizing: border-box;
        border-top: 4px solid transparent;
        cursor: default;
        &:hover {
          color: #93CA76;
          font-weight: 500;
        }
      }
      .is-active {
        color: #93CA76;
        border-top: 4px solid #93CA76;
        font-weight: 500;
      }
    }
  }
}
</style>
