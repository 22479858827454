import Mock from "mockjs";

const img1 = require("@/assets/home/plain1.png");
const img2 = require("@/assets/home/plain2.png");
const img3 = require("@/assets/home/plain3.png");

const frame1 = require("@/assets/home/frame1.png");
const frame2 = require("@/assets/home/frame2.png");
const frame3 = require("@/assets/home/frame3.png");
const frame4 = require("@/assets/home/frame4.png");
const frame5 = require("@/assets/home/frame5.png");
const frame6 = require("@/assets/home/frame6.png");

const data1 = [
  {
    id: 1,
    label: "康养智能化解决方案",
    content:
      "提供综合性的方案，旨在通过智能化的技术和设备，为康养行业提供更高效、便捷和舒适的服务，为康养行业的发展注入新的动力。",
    url: img1,
    path: "/product/solution",
  },
  {
    id: 2,
    label: "康养运营管理系统",
    content:
      "康养运营管理系统是一套集研究、开发、运营一体化的先进的系统，覆盖机构和居家场景管理系统，实现了从机构、社区到居家的一体化服务闭环。",
    url: img2,
    path: "/product/operation",
  },
  {
    id: 3,
    label: "康养功能性家具",
    content:
      "我们的适老化功能类家具，是根据老年人的特殊需求和身体特点设计制造的，旨在提供更加舒适、安全、便利的居住环境。",
    url: img3,
    path: "/product/furniture",
  },
];

Mock.mock("api/home/business", "get", () => {
  return {
    code: 200,
    msg: "请求成功",
    data: data1,
  };
});

Mock.mock("api/home/product", "get", () => {
  const data = [
    [
      { id: 1, url: frame1 },
      { id: 2, url: frame2 },
      { id: 4, url: frame3 },
    ],
    [
      { id: 9, url: frame4 },
      { id: 10, url: frame5 },
      { id: 13, url: frame6 },
    ],
  ];
  return {
    code: 200,
    msg: "请求成功",
    data: data,
  };
});
