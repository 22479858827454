// 用于存放不轻易修改的数据

export const phoneNumber = "13820538745";

export const mailbox = "77346750@qq.com";

export const address =
  "深圳市福田区香蜜湖街道香岭社区紫竹六道78号竹盛花园16栋201-O";

export const beiAn = "https://beian.miit.gov.cn";
