<template>
  <div class="layout">
    <NavBar class="sm-hidden" />
    <SmallNavbar class="lg-hidden" />
    <div class="app-main">
      <el-breadcrumb class="content-main breadcrumb" v-if="$route.meta.breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
          <span v-if="index <= levelList.length - 2" class="can-click" @click="$router.back()">{{ item.meta.title }}</span>
          <span v-else class="no-can">{{ item.meta.title }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <router-view />
      <Footer class="sm-hidden" />
      <SmallFooterVue class="lg-hidden" />
    </div>
  </div>
</template>

<script>
import NavBar from './navbar.vue'
import Footer from './footer.vue'
import SmallNavbar from './smallNavbar.vue'
import SmallFooterVue from './smallFooter.vue'
export default {
  name: 'LayoutIndex',
  components: { NavBar, Footer, SmallNavbar, SmallFooterVue },
  data() {
    return {
      levelList: null
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      const matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      if (matched.length > 1 && this.$route.path !== '/product/furnitureDetails') {
        matched.splice(1)
      }
      this.levelList = matched.filter(item => item.meta && item.meta.title)
    },
    goJump(item) {
      if (item.path === this.$route.path) return
      this.$router.push(item.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1200px) {
    .app-main {
      padding-top: 104px;
      .breadcrumb {
        padding: 16px 0;
        .can-click {
          color: #303133;
          font-weight: bold;
          cursor: pointer;
          &:hover {
            color: #93ca76;
          }
        }
        .no-can {
          color: #303133;
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .app-main {
      padding-top: 44px;
      .breadcrumb {
        display: none;
        padding: 16px 0;
      }
    }
  }
}
</style>
