import Mock from "mockjs";

const img1 = require("@/assets/about/img1.png");
const img2 = require("@/assets/about/img2.png");
const img3 = require("@/assets/about/img3.png");
const img4 = require("@/assets/about/img4.png");

Mock.mock("/api/about/getImg", "get", () => {
  return {
    code: 200,
    msg: "请求成功",
    data: [img1, img2, img3, img4],
  };
});
